import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'
import {DomSanitizer, SafeHtml} from '@angular/platform-browser'

export interface IWaitComponentData {
  /**
   * The title of the dialog
   */
  title: string

  /**
   * Text if any, either you send this or html
   */
  text?: string

  /**
   * Plain HTML if you want sanitized for you. Be
   * careful to let user data in here.
   */
  html?: string

  /**
   * If we should show the close button or not.
   * Not sure if this is part of the other dialog
   * data?
   */
  closable?: boolean
}

@Component({
  selector: 'spb-wait',
  templateUrl: './wait.component.html'
})
export class WaitComponent {

  public htmlText: SafeHtml = ''

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: IWaitComponentData) {
    if (data.html) {
      this.htmlText = this.sanitizer.bypassSecurityTrustHtml(data.html)
    }
  }
}
