import {
  IApplication,
  IApplicationOverride,
  OccupationType,
  TApplicationLogType,
  TApplicationStatus
} from '@sparbanken-syd/loan-backend'

export type TLoanPromiseStatusInput = Pick<IApplication, 'applicants' | 'status'
  | 'statusReason' | 'kalp' | 'overrides' | 'oldProperty'>

const completeStatuses: TApplicationStatus[] = ['approved', 'manually_issued']

const allowedOccupationStatuses: OccupationType[] = [OccupationType.PERMANENT, OccupationType.RETIRED]

const overrideToNoteMap: Record<keyof IApplicationOverride, TApplicationLogType> = {
  occupation: 'occupation',
  oldProperty: 'oldProperty',
  ucIncome: 'income',
  ucThreshold: 'ucThreshold',
  propertyLoansMatchingUC: 'propertyLoansMatchingUC',
  blancoLoansMatchingUC: 'blancoLoansMatchingUC',
  blancoLoansThreshold: 'blancoLoansThreshold'
}

const noteToOverrideMap: Record<TApplicationLogType, keyof IApplicationOverride | undefined> = {
  change: undefined,
  issue: undefined,
  note: undefined,
  decline: undefined,
  occupation: 'occupation',
  oldProperty: 'oldProperty',
  income: 'ucIncome',
  ucThreshold: 'ucThreshold',
  propertyLoansMatchingUC: 'propertyLoansMatchingUC',
  blancoLoansMatchingUC: 'blancoLoansMatchingUC',
  blancoLoansThreshold: 'blancoLoansThreshold'
}

/**
 * Intends to let you know what is needed to continue or not.
 */
export class LoanPromiseStatus {
  /**
   * If this cannot complete, basically b/c of uc then
   * we mark it as denied. No further actions are possible
   * (except, perhaps, adding some notes.)
   */
  public permanentlyDenied: boolean

  /**
   * If complete, we consider this an application all done and issued.
   * That means, among other things, that there is a loan promise
   * to view (PDF)
   */
  public complete: boolean
  public completeManually: boolean

  /**
   * If this is not complete it might need some income verification
   * This is true if applicant occupation is ulfed or if UC income
   * is lower than what has been given.
   */
  public needsIncomeCheck: boolean = false

  /**
   * If the KALP is not up to snuff. No loans
   * can be issued if KALP is not positive.
   */
  public needsBetterKalp: boolean = false

  /**
   * If the threshold for income compared to UC is not met.
   */
  public needsUCAboveThreshold: boolean = false

  /**
   * If the property loans are not matching the UC.
   */
  public needsPropertyLoansMatchingUC: boolean = false

  /**
   * If the blanco loans are not matching the UC.
   */
  public needsBlancoLoansMatchingUC: boolean = false

  /**
   * If the threshold for blanco loans compared to UC is not met.
   */
  public needsBlancoLoansThreshold: boolean = false

  /**
   * Superfast is denied on certain property selections
   * in this case we need a manual check.
   */
  public needsPropertyCheck: boolean = false

  /**
   * Needs to have occupation type verified.
   */
  public needsOccupationCheck: boolean = false

  /**
   * An array that contains the applicants occupation status
   * true = The applicant has good occupation
   * false = The applicant has no good occupation
   */
  public applicantOccupations: boolean[] = []

  /**
   * This is a list of all overrides that are "true"
   * that is the value has been confirmed by an admin
   */
  public overrides: Array<keyof IApplicationOverride> = []

  /**
   * This is for legacy applications that were made w/o
   * BankID. This is basically if status is _pending_ that
   * we no longer get but exist in the old database.
   */
  public legacyManual: boolean = false

  /**
   * If this application can be issued
   */
  public canBeIssued: boolean = false

  /**
   *
   */
  public declined: boolean = false

  public incomeDiffs: string[] = []

  constructor(application: TLoanPromiseStatusInput) {

    /**
     * These two assertions if for simplification
     * of test. Do not happen in real life.
     */
    application.applicants = application.applicants ?? []
    application.applicants[0] = application.applicants[0] || {}
    /**
     * First check if this can be completed at all.
     */
    this.permanentlyDenied = this.completeForbidden(application)

    /**
     * Kalp is "optional" in interface, so we assert it here.
     * If it needs better kalp it does
     */
    const kalp = application.kalp ?? 0
    this.needsBetterKalp = kalp < 1


    // Check if 'statusReason' contains 'ucThreshold', set 'needsUCAboveThreshold' to true if it does, otherwise set it to false
    this.needsUCAboveThreshold = application.statusReason?.includes('ucThreshold') ?? false

    // Check if 'statusReason' contains 'propertyLoansMatchingUC', set 'needsPropertyLoansMatchingUC' to true if it does, otherwise set it to false
    this.needsPropertyLoansMatchingUC = application.statusReason?.includes('propertyLoansMatchingUC') ?? false

    // Check if 'statusReason' contains 'blancoLoansMatchingUC', set 'needsBlancoLoansMatchingUC' to true if it does, otherwise set it to false
    this.needsBlancoLoansMatchingUC = application.statusReason?.includes('blancoLoansMatchingUC') ?? false

    // Check if 'statusReason' contains 'blancoLoansThreshold', set 'needsBlancoLoansThreshold' to true if it does, otherwise set it to false
    this.needsBlancoLoansThreshold = application.statusReason?.includes('blancoLoansThreshold') ?? false


    /**
     * In about six months we can remove this.
     */
    if (!application.applicants[0].uc) {
      this.legacyManual = true
    }

    /**
     * Then, check if it is complete. The check against
     * permanent denied should be superfluous
     */
    this.complete = !this.permanentlyDenied &&
      completeStatuses.indexOf(application.status) !== -1 &&
      // Remove this check in the future (approx. July 2024
      !this.legacyManual
    this.completeManually = this.complete && application.status === 'manually_issued'

    // OK, it is not complete, and it is not forbidden then what is needed?
    if (!this.complete && !this.permanentlyDenied) {
      this.needsIncomeCheck = this.checkIfIncomeIsNeeded(application)

      // General overrides check
      this.needsUCAboveThreshold = this.generalOverridesCheck(application, 'ucThreshold', this.needsUCAboveThreshold)
      this.needsPropertyLoansMatchingUC = this.generalOverridesCheck(application, 'propertyLoansMatchingUC', this.needsPropertyLoansMatchingUC)
      this.needsBlancoLoansThreshold = this.generalOverridesCheck(application, 'blancoLoansThreshold', this.needsBlancoLoansThreshold)
      this.needsBlancoLoansMatchingUC = this.generalOverridesCheck(application, 'blancoLoansMatchingUC', this.needsBlancoLoansMatchingUC)

      this.needsPropertyCheck = this.checkIfPropertyCheckIsNeeded(application)
      this.needsOccupationCheck = this.checkIfOccupationCheckIsNeeded(application)
    }

    if (application.overrides) {
      this.overrides = Object.entries(application.overrides)
        .filter(override => override[1])
        .map(override => override[0] as keyof IApplicationOverride)
    }

    this.canBeIssued = application.status === 'issuable'
    this.declined = application.status === 'declined' as any
  }

  // Checks if a specific property within the 'overrides' object has been explicitly set to false.
  // It returns true if the property is not explicitly set to false, allowing default values to be overridden.
  private generalOverridesCheck(application: TLoanPromiseStatusInput, propertyName: string, defaultValue: boolean): boolean {
    // Checks if the 'overrides' object exists and contains the specified property.
    if (application.overrides?.[propertyName] !== undefined) {
      // Returns true if the property is not explicitly set to false, indicating that the default value should be used instead.
      return application.overrides[propertyName] !== true
    } else {
      // If the property does not exist in the 'overrides' object, it returns the default value.
      return defaultValue
    }
  }

  /**
   * Translates the type of override to a note to be sent
   */
  public overrideToNote(override: keyof IApplicationOverride): TApplicationLogType {
    return overrideToNoteMap[override]
  }

  /**
   * Verifies if this is an override or not.
   * @param type
   */
  public hasOverride(type: TApplicationLogType): boolean {
    if (!noteToOverrideMap[type]) {
      return false
    } else {
      return this.overrides.indexOf(noteToOverrideMap[type] as keyof IApplicationOverride) !== -1
    }
  }

  /**
   * Under some circumstance we can never proceed. E.g. if UC says not.
   */
  private completeForbidden(application: TLoanPromiseStatusInput): boolean {
    return application.status === 'rejected' && application.statusReason?.includes('uc') === true
  }

  private checkIfIncomeIsNeeded(application: TLoanPromiseStatusInput): boolean {
    /**
     * If override is already done then this is done.
     * Or if no uc is done then this cannot be checked
     */
    if (application.overrides?.ucIncome === true || this.legacyManual) {
      return false
    }
    // This happens when the input income differs
    this.incomeDiffs = application.applicants
      .map(a => {
        if (a.uc?.incomeDiff !== 0) {
          const given = a.income as number * 12
          const uc = Number.parseInt(a.uc!.income[0] + '')
          if (given > uc) {
            return `Uppgivit ${given} vilket` +
              ` är ${Math.round(a.uc!.incomeDiff * 100)}% mer än UC (${uc})`
          }
        }
        return ''
      })
    return this.incomeDiffs.filter(d => d).length > 0
  }

  /**
   * Checks the old property this is a "superfast" property, if
   * Intend to sell, then this needs to be checked. Override is possible
   */
  private checkIfPropertyCheckIsNeeded(application: TLoanPromiseStatusInput): boolean {
    // No old property, that is fine.
    if (!application.oldProperty) {
      return false
    }
    /**
     * If old property and not overridden, then yes, must be checked
     * Complex if provided by intellisense.
     */
    return application.oldProperty.futurePropertyOwnership === 'intendToSell' &&
      application.overrides?.oldProperty !== true
  }

  private checkIfOccupationCheckIsNeeded(application: TLoanPromiseStatusInput): boolean {
    // If any is false you can check here if one or both.
    this.applicantOccupations = application.applicants
      .map(a => a.occupation)
      .map(o => allowedOccupationStatuses.indexOf(o) !== -1)
    // If not all is true none is true.
    return !this.applicantOccupations.every(s => s) && !application.overrides?.occupation
  }
}
